import { render, staticRenderFns } from "./OrderPubAptPaymentBlock.vue?vue&type=template&id=bcbd1ca6&scoped=true"
import script from "./OrderPubAptPaymentBlock.vue?vue&type=script&lang=js"
export * from "./OrderPubAptPaymentBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcbd1ca6",
  null
  
)

export default component.exports