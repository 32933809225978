<template>
  <div v-if="syncTickets.length">
    <p class="section-title text-primary-100 font-medium">堂票預約資訊</p>
    <BaseElForm label-position="left" label-width="150px">
      <BaseElFormItem label="堂票名稱">
        {{ displayData.name }}
      </BaseElFormItem>
      <BaseElFormItem label="堂票預約狀態">
        <div class="flex items-center h-[40px]" style="gap: 10px">
          <Tag :type="displayData.status.tagType">{{ displayData.status.label }}</Tag>
          <div v-if="displayData.statusCode === 'booking'" class="underline text-primary-100 cursor-pointer" @click="show.refundWarning = true">
            退回堂票
          </div>
        </div>
      </BaseElFormItem>
      <p v-if="!classTicketStillCanUse && displayData.statusCode === 'booking'" class="text-danger text-sub">* 變更後的服務項目不適用已選堂票，請退回堂票</p>
      <BaseElFormItem label="堂票序號">
        {{ displayData.code }}
      </BaseElFormItem>
      <BaseElFormItem label="堂票總價">
        {{ displayData.totalPrice }}
      </BaseElFormItem>
    </BaseElForm>

    <WarningDialog
      v-if="show.refundWarning"
      title="提醒"
      cancelBtnString="返回"
      btnString="確認退回"
      btnType="danger"
      @confirm="onRefund"
      @close="show.refundWarning = false"
    >
      <template slot="body">
        <div>
          <p>是否確定將堂票退還給顧客？</p>
        </div>
      </template>
    </WarningDialog>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, reactive, watch } from 'vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { GetReservationTicket, UpdateReservationTicketStatus } from '@/api/reservationTicket'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { get, map, join } from 'lodash'
import { useVModel } from '@vueuse/core'
import notifyMessage from '@/config/notifyMessage'

const statusConfig = {
  booking: { label: '已預扣', tagType: 'action' },
  unbooked: { label: '已退回', tagType: 'danger' },
  used: { label: '已使用', tagType: 'success' },
}

export default defineComponent({
  name: 'ClassTicketDetailBlock',
  components: { WarningDialog },
  props: {
    data: { type: Object, default: () => ({}) },
    reservationId: String,
    orderId: String,
    blockIndex: Number,
    classTickets: Array,
    service: { type: Object, default: () => ({}) },
    orderStatus: String,
  },
  setup (props, { emit }) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const syncTickets = useVModel(props, 'classTickets', emit)
    // const tickets = ref([])
    const show = reactive({
      refundWarning: false,
    })
    const classTicketUnavailable = ref(false)

    const displayData = computed(() => {
      const ticket = get(syncTickets.value, '[0]')
      const record = get(syncTickets.value, '[0].ClassTicketRecord')
      const serials = get(syncTickets.value, '[0].BookedRecordUse.ClassTicketRecordSerials')
      const status = get(ticket, 'status')
      return {
        id: get(record, 'id'),
        name: get(record, 'name'),
        status: get(statusConfig, status),
        statusCode: status,
        code: join(map(serials, 'code'), '、'),
        totalPrice: get(record, 'price'),
      }
    })

    const onRefund = async () => {
      const [res, errMsg, rawErr] = await UpdateReservationTicketStatus({
        shopId: shopId.value,
        id: get(syncTickets.value, '[0].id'),
        status: 'unbooked',
      })
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      show.refundWarning = false
      refresh()
      emit('refresh')
      window.$message.success(notifyMessage.updateSuccess)
    }

    const classTicketStillCanUse = computed(() => {
      const classTicketId = get(syncTickets.value, '[0].ClassTicketRecord.ClassTicketId')
      const serviceClassTickets = map(get(props.service, 'ClassTickets'), 'id')
      return serviceClassTickets.includes(classTicketId)
    })

    const refresh = async () => {
      simpleFetch(GetReservationTicket, {
        shopId: shopId.value,
        AppointmentOrderId: props.orderId,
        AppointmentReservationId: props.reservationId,
      }, res => {
        syncTickets.value = res
      })
    }

    watch(() => props.orderStatus, async () => refresh())

    onMounted(async () => {
      refresh()
    })

    return {
      displayData,
      show,
      onRefund,
      syncTickets,
      classTicketStillCanUse,
      classTicketUnavailable,
    }
  },
})

</script>

<style lang="postcss" scoped>

</style>
